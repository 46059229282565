import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
const Header = lazy(() => import("./Components/Header/Header"));
const Footer = lazy(() => import("./Components/Footer/Footer"));

// Pages
const HomePage = lazy(() => import("./Pages/HomePage"));
const CoursePage = lazy(() => import("./Pages/CoursePage"));
const SyllabusPage = lazy(() => import("./Pages/SyllabusPage"));
const CourseDetailPage = lazy(() => import("./Pages/CourseDetailPage"));
const Sidebar = lazy(() => import("./Components/Sidebar/Sidebar"));
const TalksPage = lazy(() => import("./Pages/TalksPage"));
const TestimonialsPage = lazy(() => import("./Pages/TestimonialsPage"));
const CareerPage = lazy(() => import("./Pages/CareerPage"));
const HirePage = lazy(() => import("./Pages/HirePage"));
const RecruitmentPage = lazy(() => import("./Pages/RecruitmentPage"));
const FixedPosters = lazy(() => import("./Pages/FixedPosters"));
const TermsNConditions = lazy(()=>import("./Pages/TermsNConditions"));
const Blogs = lazy(()=> import("./Pages/BlogsPage"));

function App() {

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{ width: "100vw", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            LOADING...
          </div>
        }
      >
        <Header />
        <Sidebar />
        <FixedPosters />
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/courses" element={<CoursePage />}></Route>
          <Route path="/syllabus" element={<SyllabusPage />}></Route>
          <Route path="/course-detail" element={<CourseDetailPage />}></Route>
          <Route path="/talk" element={<TalksPage />}></Route>
          <Route path="/testimonials" element={<TestimonialsPage />}></Route>
          <Route path="/career" element={<CareerPage />}></Route>
          <Route path="/hire-from-us" element={<HirePage />}></Route>
          <Route path="/recruitment" element={<RecruitmentPage />}></Route>
          <Route path="/termsNConditions" element={<TermsNConditions />}></Route>
          <Route path="/blogs" element={<Blogs/>}></Route>
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
